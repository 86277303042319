:root {
    --color1: #6f42c1;
    --color2: #c22579;
    --mainbg: #faf5df;

}

.page a {
    color: var(--color2);
}

#sitebody {
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(245, 250, 223, 1) 57%, rgba(245, 250, 223, 1) 100%);
    min-width: 280px;
    font-family: "Georgia", "Times New Roman", sans-serif;
    color: var(--color2);


    .toptitle {
        font-weight: bold;
        //text-shadow: 1px 2px 3px #ffc107;
    }

    .color1 {
        color: var(--color1);
    }

    .bg1 {
        background-color: var(--color1);
    }

    .color2 {
        color: var(--color2);
    }

    .bg2 {
        background-color: var(--color2);
    }

    .soc-contact {
        text-decoration: none;
    }

    .soc-contact:hover {
        color: var(--color1);
        text-decoration: underline;
        /*transform: scale(1);*/
    }

    .btn-outline-primary {
        color: var(--bs-white);
        border-color: var(--bs-white);
    }

    .btn-outline-primary:hover,
    .btn-outline-primary:focus,
    .btn-outline-primary:visited {
        color: var(--color2);
        border-color: var(--color2);
        background-color: var(--bs-white);
    }

    .form-control {
        background-color: var(--mainbg);
        border-color: var(--color2);
        color: var(--color2);
    }

    .form-control:disabled {
        background-color: #e9ecef;
        border-color: #ababab;
    }

    .form-control::placeholder {
        color: var(--color2);
    }

    .form-control:focus {
        color: var(--color2);
        background-color: var(--bs-white);
        border-color: var(--color1);
        outline: 0;
        box-shadow: 0 0 0 .25rem rgba(194, 37, 121, .25);
    }

    .lk-menu .nav-link.active, .lk-menu .nav-link.active:hover {
        color: white;
    }

    .lk-menu .nav-link {
        color: var(--color2);
        border: solid 1px var(--color2);
    }

    .lk-menu .nav-link:hover {
        text-decoration: underline;
        color: var(--color2);
    }


    .nav-link, .navbar-brand, .navbar-toggler {
        color: var(--bs-white);
    }

    .nav-link:hover, .navbar-brand:hover {
        color: var(--bs-yellow);
        text-decoration: underline;
    }

    .navbar-dark .navbar-nav .nav-link {
        color: var(--mainbg);
    }

    .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show > .nav-link {
        /*color: #f1fd8d;*/
        /*text-decoration: underline;*/
        text-shadow: 0px 0px 7px rgba(247, 235, 75, 1);
        text-decoration: underline;
        /*text-shadow: 0px 0px 1px rgba(0, 0, 0, 1);*/
    }

    .navbar-dark {
        background-color: var(--color2);
        color: var(--bs-white);
        border-radius: 0.7rem;
    }

    .fl-good img {
        border-radius: 3rem;
        border: 3px solid var(--color2);
        width: 12rem;
        height: 12rem;
    }

    .fl-good > a {
        text-decoration: none;
    }

    .fl-good > a:hover {
        text-decoration: underline;
    }

    #fl-goods {
        /*background-color: var(--bs-white);*/
    }

    .nav-item {
        margin-right: 2rem;
    }

    .fl-gradient {

        display: inline-block;
        position: relative;
        line-height: 0;

    }

    .fl-gradient:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(to right, rgba(245, 250, 223, 1) 0%, rgba(245, 250, 223, 1) 1%, rgba(255, 0, 0, 0) 15%),
        linear-gradient(to left, rgba(245, 250, 223, 1) 0%, rgba(245, 250, 223, 1) 1%, rgba(255, 0, 0, 0) 15%);


    }

    /* ckeditor styles for images aligment */
    .image_resized {
        box-sizing: border-box;
        display: block;
        max-width: 100%;
    }

    .image, .image-inline {
        margin: 0 auto;
        position: relative;
    }

    .image-style-side {
        float: right;
        margin-left: 1rem;
        max-width: 50%;
    }

    .image > img {
        margin: 0 auto;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    #carouselHero .carousel-item img {
        object-fit: cover;
        object-position: center;
        height: 50vh;

        overflow: hidden;
    }

    .accordion {
        --bs-accordion-bg: transparent;
    }

    .accordion-button:not(.collapsed) {
        color: var(--color2);
        background-color: rgba(255, 177, 193, 0.49);
        //box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--color2);
    }

    .accordion-button:focus {
        border-color: var(--color2); //var(--bs-accordion-btn-focus-border-color);
        outline: 0;
        box-shadow: none;
    }

    .like{
        cursor: pointer;
    }

    .like:hover{
        color: red;
    }

    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        background: var(--color2);

    }
}

.video-wrap {
    position: relative;
    padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
    height: 0;
    overflow: hidden;
}
.video-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 0;
    outline-width: 0;
}

@include media-breakpoint-up(xxl) {
    .fs-xxl-5 {
        font-size: 1.25rem !important;
    }
}
